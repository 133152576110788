import { ReactSVG } from 'react-svg';
import './modal.scss';
import './modal.mobile.scss';
import closeIcon from '../../assets/icons/circle-xmark.svg'

import { ReactNode } from 'react';

interface ModalData {
    onClose?: () => void;
    children?: ReactNode;
}

const Modal: React.FC<ModalData> = ({onClose, children}) => {

    return (
        <>
        <div className="component modal">
            
            <div className="modal-container">
                <div className="close-icon">
                    <ReactSVG src={closeIcon} onClick={onClose}/>
                </div>
                <div className="modal-content">{children}</div>
            </div>
        </div>
           
        </>
    );
}


export default Modal;