import './form-details-item.scss';


interface FormDetailsItemProps {
    label: string;
    itemValue: string;
    className?:string;
}



function FormDetailsItem({label, itemValue,className}:FormDetailsItemProps) {

    let itemClassName='form-item-details '+className;

    return (<>
        <div className={itemClassName}>
            <label htmlFor="name">{label}</label>
            <input className='form-item-content' type='text' disabled={true} id="name" value={itemValue} />
        </div>
    </>);
}


export default FormDetailsItem;