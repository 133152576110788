import http from "../utils/http-common";
import { ApiResponse } from "../models/api-response";
import { Booking } from "../models/booking";
import {BookingRequestDTO} from "../models/booking-request-dto";

const getAllBooking = async (params?: any) => {
    try {
        if (params !== undefined) {
            const formattedParams = {
                names: params && params.params.name ? params.params.name.join(',') : '',
                retailerReferencesBeneficiary: params && params.params.retailerReference ? params.params.retailerReference.join(',') : '',
                states: params && params.params.state ? params.params.state.join(',') : '',
                subsidiariesBeneficiary: params && params.params.subsidiaryBeneficiary ? params.params.subsidiaryBeneficiary.join(',') : '',
                bookingsDates: params && params.params.bookingDate ? params.params.bookingDate.join(',') : '',
                numbersOfGuests: params && params.params.numberOfGuests ? params.params.numberOfGuests.join(',') : '',
                bookingsHours: params && params.params.bookingHour ? params.params.bookingHour.join(',') : '',
                page: params.params.page,
                size: params.params.size,
                order: params.params.order,
            };
            params = { params : formattedParams }
        }

        const response = await http.get<ApiResponse<Booking[]>>("/bookings", params);
        return response.data.data;
    } catch (error: any) {
        console.error(error);
        throw error;
    }
};

const getBookingById = async(id: string) => {
    try {
        const response = await http.get<ApiResponse<Booking>>(`/bookings/${id}`);
        return response.data.data;
    } catch (error: any) {
        if (error.response && error.response.status === 401) {
            window.location.href = "/";
          }
        if(error)
        console.error(error);
        throw error;
    }
}

const createBooking = async(booking: BookingRequestDTO) => {
    try {
        const response = await http.post<ApiResponse<Booking>>("/bookings", booking);
        return response.data.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const getUserBooking = async () => {
    try {
        const response = await http.get<ApiResponse<Booking>>("/bookings/user");
        return response.data.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const putBooking = async (booking: BookingRequestDTO) => {
    try {
        const response = await http.put<ApiResponse<Booking>>("/bookings", booking);
        return response.data.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const count = async () => {
    try {
        const response = await http.get<ApiResponse<number>>("/bookings/count");
        return response.data.data
    } catch (error) {
        return -1;
    }
}


const exportExcel = async (params?: any) => {
    try {
        let response;

        let queryString = '';
        const fileName = 'exportedBookings.xlsx';

        if (params !== undefined) {
            const paramArray = [];

            if (params.name) paramArray.push(`names=${params.name.join(',')}`);
            if (params.state) paramArray.push(`states=${params.state.join(',')}`);
            if (params.subsidiaryBeneficiary) paramArray.push(`subsidiariesBeneficiary=${params.subsidiaryBeneficiary.join(',')}`);
            if (params.bookingDate) paramArray.push(`bookingsDates=${params.bookingDate.join(',')}`);
            if (params.numberOfGuests) paramArray.push(`numbersOfGuests=${params.numberOfGuests.join(',')}`);
            if (params.bookingHour) paramArray.push(`bookingsHours=${params.bookingHour.join(',')}`);

            queryString = paramArray.join('&');
        }

        response = await http.get(`/bookings/files/export-excel?${queryString}`, {
            responseType: 'blob',
            params: { fileName: fileName }
        });

        if (response.data instanceof Blob) {
            const blobUrl = window.URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = fileName;

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } else {
            console.error('Unexpected response format:', response.data);
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const BookingService = {
    getAllBooking,
    createBooking,
    getUserBooking,
    putBooking,
    count,
    exportExcel,
    getBookingById
};

export default BookingService;
