import {useState} from 'react';
import BookingButton from '../../../components/booking-button/booking-button';
import TextField, {TextFieldTypes} from '../../../components/text-field/text-field';

import './fourth-page.scss';

interface FourthPageProps {
    sendValueToParent?: (value: {
        firstName: {
            firstName: string;
        };
        lastName: {
            lastName: string;
        };
        retailerReference: {
            retailerReference: string;
        };
        subsidiary: {
            subsidiary: string;
        };
        email: {
            email: string;
        };
    }) => void;
}


function FourthPage({sendValueToParent}: FourthPageProps) {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [retailerReference, setRetailerReference] = useState('');
    const [subsidiary, setSubsidiaryBeneficiary] = useState('');
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isEmptyFields, setIsEmptyFilds] = useState(false);
    // Callback function to update the input value

    const handleFirstNameChange = (value: string) => {
        setFirstName(value);
    };

    const handleLastNameChange = (value: string) => {
        setLastName(value);
    };

    const handleRetailerReferenceChange = (value: string) => {
        setRetailerReference(value);
    };

    const handleSubsidiaryChange = (value: string) => {
        setSubsidiaryBeneficiary(value);
    };

    const handleEmailChange = (value: string) => {
        setEmail(value);
    };

    let recievedValueFromButton = "";

    const handleReceiveValueFromChild = (value: string) => {
        recievedValueFromButton = value;
    };

    const handleSendValue = () => {
        const fields = [firstName, lastName, retailerReference, subsidiary, email];
        const nonEmptyFields = fields.filter(field => field !== null && field !== '');
        if(nonEmptyFields.length !== 0 && nonEmptyFields.length < 5) {
            setIsEmptyFilds(true);
            if(email !== '') {
                const isValid = email.endsWith('@rolex.com');
                if(!isValid){
                    setIsValidEmail(false);
                }
            }
        }
        else {
            setIsEmptyFilds(false);
            if(email !== '') {
                setIsEmptyFilds(false);
                const isValid = email.endsWith('@rolex.com');
                if (isValid) {
                    setIsValidEmail(true);
                    const valueToSend = {"firstName": {firstName}, 
                                        "lastName": {lastName}, 
                                        "retailerReference": {retailerReference}, 
                                        "subsidiary": {subsidiary}, 
                                        "email": {email}};
                    if (sendValueToParent) {
                        sendValueToParent(valueToSend);
                    }
                } else {
                    setIsValidEmail(false);
                }
            } else {
                const valueToSend = {"firstName": {firstName}, 
                                        "lastName": {lastName}, 
                                        "retailerReference": {retailerReference}, 
                                        "subsidiary": {subsidiary}, 
                                        "email": {email}};
                if (sendValueToParent) {
                    sendValueToParent(valueToSend);
                }
            }
            
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if(firstName !== '' && lastName !== '' && subsidiary !== '' && email !== '') {
                handleSendValue();
            }
        }
    };
    return (
        <>
            <div className="page-title">
                <p className='description-fourth'>Book a table</p>
                <h1 className='description-title-1'>Optional benefeciary information</h1>
                <h1 className='description-title-2'> needed</h1>
            </div>
            <div className='page-content'>
                <div className='content-fields'>
                    <TextField placeHolder='First Name' onChange={handleFirstNameChange} value={firstName} handleKeyDown={handleKeyDown} isEmptyField={isEmptyFields}/>
                    <TextField placeHolder='Last Name' onChange={handleLastNameChange} value={lastName} handleKeyDown={handleKeyDown} isEmptyField={isEmptyFields}/>
                    <TextField placeHolder='Retailer Reference' onChange={handleRetailerReferenceChange} value={retailerReference} handleKeyDown={handleKeyDown}/>
                    <TextField placeHolder='Affiliate' onChange={handleSubsidiaryChange} value={subsidiary} handleKeyDown={handleKeyDown}/>
                    <TextField placeHolder='E-mail' onChange={handleEmailChange} value={email}
                               type={TextFieldTypes.Email} isValidEmail={isValidEmail} 
                               handleKeyDown={handleKeyDown} />
                    <div className='next-field'>
                        <BookingButton content='Next' onClick={handleSendValue}
                            sendValueToParent={handleReceiveValueFromChild}/>
                    </div>
                </div>
            </div>

        </>
    );
}

export default FourthPage;