import { ReactNode } from "react";
import Cover from "../cover/cover";
import './page-template.scss';
import './page-template-mobile.scss';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import ScrollUpButton from "../scroll-up-button/scroll-up-button";

interface PageTemplateProps {
    children?: ReactNode;
    navMenu?:ReactNode;
    goBack?: () => number;
}


function PageTemplate({ children, navMenu, goBack }: PageTemplateProps) {

    const navigate = useNavigate();
    const handleGoBack = () => {
      if (goBack) {
        let formNumber = goBack();
        if (formNumber === 0) {
          navigate(-1);
        }
      } else {
        navigate(-1);
      }
  
    }
    return (
        <div id="page-template-container" className="mobile page-template-container">
          <div className="page-template-cover-container">
            <Cover />
          </div>
          <div className="page-template-content-container">
            <div className="page-template-content-card">
                <div className="back-actions">
                <button className="btn-back" onClick={handleGoBack} type="button">
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>                    
                    <div className="main-actions">{navMenu}</div>
                </div>

                {children}
            </div>
            <div className="mobile actions">
              <ScrollUpButton/>
            </div>
          </div>
        </div>
    );
}

export default PageTemplate;


