import BookingButton, { BookingButtonRole } from "../../../components/booking-button/booking-button";
import numbers from './numbers.json';
import { useState } from 'react';

interface ThirdPageProps {
    sendValueToParent?: (value: string) => void;
}



function ThirdPage({sendValueToParent}:ThirdPageProps) {

    const [selectedValue, setSelectedValue] = useState<string>('');

    let recievedValueFromButton = "";

    const handleReceiveValueFromChild = (value: string) => {
        recievedValueFromButton = value;
        setSelectedValue(value);
    };

    const handleSendValue = () => {
        const valueToSend = recievedValueFromButton;
        if (sendValueToParent) {
            sendValueToParent(valueToSend);
        }
    };

    return (
        <>
        
        <div className="page-title">
          <p>Book a table</p>
          <h1 className="third-title">Guest number</h1>
        </div>
        <div className="numbers-container">
          {numbers.numbers.map((number, index) => ((
            <div key={index / 3} className="number-group">
              <BookingButton
                key={index}
                content={number}
                onClick={handleSendValue}
                sendValueToParent={handleReceiveValueFromChild}
                role={BookingButtonRole.BookTime}
                selectedValue={selectedValue}
                />
            </div>
          )))}
        </div>
        </>
    );
}

export default ThirdPage;