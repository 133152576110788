import './scroll-up-button.scss';
import arrowUp from '../../assets/icons/arrow-up.svg'
import { ReactSVG } from "react-svg";
import Icon from '../icon/icon';

const ScrollUpButton: React.FC = () => {
    
    // Scroll to bottom of page
    function handleClick() {
        const pageTemplateContainer = document.getElementById('page-template-container') as HTMLDivElement;
        const scrollElement = pageTemplateContainer;

                
        scrollElement.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

    }

    return <button className="scroll-up-button" type="button" onClick={handleClick}>
        <div className="scroll-up-button-content">
            <Icon icon={arrowUp} />
            Scroll up
        </div>
        
    </button>;
}

export default ScrollUpButton;