import BookingButton from "../../../components/booking-button/booking-button";
import dates from './dates.json';
import { useState } from 'react';


interface FirstPageProps {
    sendValueToParent?: (value: string) => void;
}



function FirstPage({ sendValueToParent }: FirstPageProps) {
    const [selectedValue, setSelectedValue] = useState<string>('');

    let recievedValueFromButton = "";

    const handleReceiveValueFromChild = (value: string) => {
        recievedValueFromButton = value;
        setSelectedValue(value);
    };

    const handleSendValue = () => {
        const valueToSend = recievedValueFromButton;
        if (sendValueToParent) {
            sendValueToParent(valueToSend);
        }
    };


    return (
        <>
            <div className="page-title">
                <p>Book a table</p>
                <h1>Please select a date</h1>
            </div>
            {dates.dates.map((date, index) => (
                <BookingButton key={index} content={date} onClick={handleSendValue} sendValueToParent={handleReceiveValueFromChild} 
                selectedValue={selectedValue} />
            ))}
        </>
    );
}


export default FirstPage;