import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './login-callback-page.scss';
import logoRolex from '../../assets/images/Rolex.webp';

const LoginCallbackPage = () => {
    const oktaAuth = useOktaAuth();
    const navigate = useNavigate();

    const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
        navigate(originalUri, { replace: true });
      };


    useEffect(() => {
        const handleAuthentication = async () => {
          try {
            // Récupérer le résultat de l'authentification depuis le hash de l'URL
            await oktaAuth.oktaAuth.handleLoginRedirect();
          } catch (error) {
            // Gérer les erreurs d'authentification
            console.error('Erreur lors du traitement de la redirection de connexion:', error);
          } finally {
            // Rediriger vers l'URL d'origine après la connexion réussie
            let firstKey = null;
            let originalUri =  null;
            for (let i = 0; i < sessionStorage.length; i++) {
              const key = sessionStorage.key(i);
              if (key && key.startsWith("urls")) {
                firstKey = key;
                originalUri = sessionStorage.getItem(key);
                break; // Stop the loop once the first "kk" key is found
              }
            }
            if(originalUri) {
                if(originalUri.includes("login/callback")){
                  await restoreOriginalUri(oktaAuth.oktaAuth, "/");
                } else {
                  await restoreOriginalUri(oktaAuth.oktaAuth, originalUri);
                }
            }
          }
        };

        handleAuthentication();
    }, [oktaAuth, navigate, restoreOriginalUri]);

    return (
      <div className="container">
        <div>
          <img src={logoRolex} alt="Rolex" />
        </div>
      </div>
    );
};

export default LoginCallbackPage;