import BookingButton, { BookingButtonRole } from "../../../components/booking-button/booking-button";
import FormDetailsItem from "../../../components/form-details-item/form-details-item";
import './submit-page.scss';
import { useNavigate } from "react-router-dom";

interface SubmitPageProps {
    name: string;
    affiliate: string;
    date: string;
    time:string;
    guest: string;
    beneficiary: string;
    subsidiary: string;
    retailerReference: string;
    onClick: () => void;
}

function SubmitPage({ affiliate, beneficiary, retailerReference, date, guest, name, time, subsidiary, onClick }: SubmitPageProps) {

    const navigate = useNavigate();
    const goToBookingPage = () => {
        navigate(-1);
    }
    return (<>
    <div className="submit-page-container">
        <div className="submit-page-content">
            <FormDetailsItem label="Name" itemValue={name}></FormDetailsItem>
            <FormDetailsItem label="Affiliate" itemValue={affiliate}></FormDetailsItem>
            <div className="event-date-time">
                <FormDetailsItem label="Date" itemValue={date} className="only-date"></FormDetailsItem>
                <FormDetailsItem label="Hour" itemValue={time} className="only-hour"></FormDetailsItem>
            </div>
            <FormDetailsItem label="Guests" itemValue={guest}></FormDetailsItem>
            <FormDetailsItem label="Beneficiary" itemValue={beneficiary}></FormDetailsItem>
            <FormDetailsItem label="Beneficiary Affiliate" itemValue={subsidiary}></FormDetailsItem>            
            <FormDetailsItem label="Retailer Reference" itemValue={retailerReference}></FormDetailsItem>            
        </div>
        <div className="submit-button">
            <BookingButton content='Send my request'  role={BookingButtonRole.SendRequest} onClick={onClick}/>
        </div>
        <br />
        <div className='cancel-field'>
            <BookingButton content='Cancel' role={BookingButtonRole.CancelRequest} onClick={goToBookingPage}/>
        </div>
    </div>
    </>);
}

export default SubmitPage;