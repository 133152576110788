import React from "react"; 
import './App.scss';
import { Security } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Route, Routes, useNavigate } from 'react-router-dom';
import LoginCallbackPage from './pages/login-callback-page/login-callback-page';
import ElementsPage from './pages/elements-page/ElementsPage';
import RestaurantPage from './pages/restaurant-page/restaurant-page';
import PrivateRoute from './components/routing/private-router';
import oktaAuth from './security/okta-config';
import ContactPage from './pages/contact-page/contact-page';
import BookingPage from './pages/booking-page/booking-page';
import ViewBooking from './pages/view-bookings-page/view-bookings-page';
import { SetUserName } from './services/getUserFromToken';
import BookingDetailPage from './pages/booking-detail-page/booking-detail-page';

function App() {
  const refreshOkta = () => {
    oktaAuth.session.refresh()
        .then(function(session) {
            console.log('Session refreshed successfully:', session);
        })
        .catch(function(err) {
            console.error('Error refreshing session:', err);
        });
  }  
  React.useEffect(() => {
    const intervalId = setInterval(refreshOkta, 4 * 60 * 1000);
        return () => clearInterval(intervalId);
  }, [oktaAuth]);
  return (
    <Routes>
      <Route path='/' element={<PrivateRoute />}>
        <Route path='/' element={<RestaurantPage />} />
        <Route path='/reservation/42' element={<RestaurantPage />} />
        <Route path='/elements' element={<ElementsPage />} />
        <Route path='/view-booking' element={<ViewBooking />} />
        <Route path='/contact-us' element={<ContactPage />} />
        <Route path='/booking' element={<BookingPage />} />
        <Route path='/booking-detail/:id' element={<BookingDetailPage />} />
      </Route>
      <Route path="/login/callback" element={<LoginCallbackPage/>} />
    </Routes>
  );
}


function BaseApp() {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin), { replace: true });
  };

  SetUserName(oktaAuth);

  return (
    <div className="App">
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <main>
            <App/>
          </main>
        </Security>
    </div>
  );
}


export default BaseApp;
