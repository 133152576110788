import './booking-page.scss';
import PageTemplate from "../../components/page-template/page-template";
import FirstPage from "./first-page/first-page";
import SecondPage from "./second-page/second-page";
import ThirdPage from "./third-page/third-page";
import FourthPage from "./fourth-page/fourth-page";
import { useEffect, useState } from 'react';
import SubmitPage from './submit-page/submit-page';
import GetUserName from '../../services/getUserFromToken';
import BookingService from '../../services/booking-service';
import {BookingRequestDTO} from '../../models/booking-request-dto';
import {ReactSVG} from 'react-svg';
import calendar from '../../assets/icons/calendar.svg';
import clock from '../../assets/icons/clock.svg';
import people from '../../assets/icons/people.svg';
import { Booking } from '../../models/booking';
import Modal from '../../components/modal/modal';
import StandardButton, { ButtonStatus } from "../../components/buttons/StandardButton";
import rolexLogo from '../../assets/images/Rolex.webp';
import { useNavigate } from 'react-router';
import { BookingState } from '../../models/BookingState';
import { User } from '../../models/user';
import UserService from '../../services/user-service';

function BookingPage() {
    const navigate = useNavigate();
    const [userSubsidiary, setUserSubsidiary] = useState<string>('');
    let userName = GetUserName();
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userRetailerReference, setUserRetailerReference] = useState('');
    const [subsidiaryBeneficiary, setSubsidiaryBeneficiary] = useState('');
    const [userEmail, setUserEmail] = useState('');

    //Icons Status
    const [dateIconStatus, setDateIconStatus] = useState('active');
    const [timeIconStatus, setTimeIconStatus] = useState('');
    const [guestNumberIconStatus, setGuestNumberIconStatus] = useState('');

    //Icons values
    const [dateIconValue, setDateIconValue] = useState('');
    const [timeIconValue, setTimeIconValue] = useState('');
    const [guestNumberIconValue, setGuestNumberIconValue] = useState('');


    //Display element based on steps
    const [firstPageDisplay, setFirstPageDisplay] = useState('');
    const [secondPageDisplay, setSecondPageDisplay] = useState('none');
    const [thirdPageDisplay, setThirdPageDisplay] = useState('none');
    const [fourthPageDisplay, setFourthPageDisplay] = useState('none');
    const [submitPageDisplay, setSubmitPageDisplay] = useState('none');
    const [confirmationDisplay, setConfirmationDisplay] = useState('none');

    const [formSection, setFormSection] = useState<number>(1);

    const getCurrentUser = async() => {
        try {
            let result: User = await UserService.getCurrentUser();
            if(result !== null) {
                setUserSubsidiary(result.subsidiary.name);
            } else {
                navigate('/');
            }
        } catch(error) {
            navigate('/');
        }
    } 

    useEffect(() => {
        getCurrentUser();
    })

    const handleReceiveValueFromFirstPage = (value: string) => {
        const date = new Date(value);
        const formattedDate = date.toLocaleDateString('en-US', {month: 'long', day: 'numeric', year: 'numeric'});
        setDateIconValue(formattedDate);

        setDateIconStatus("done");
        setTimeIconStatus("active");
        setGuestNumberIconStatus("");

        setFirstPageDisplay("none");
        setSecondPageDisplay("");
        setThirdPageDisplay("none");
        setFourthPageDisplay("none");
        setSubmitPageDisplay("none");
        setFormSection(2);
        setConfirmationDisplay("none")
    };

    const handleReceiveValueFromSecondPage = (value: string) => {
        setTimeIconValue(value);

        setDateIconStatus("done");
        setTimeIconStatus("done");
        setGuestNumberIconStatus("active");

        setFirstPageDisplay("none");
        setSecondPageDisplay("none");
        setThirdPageDisplay("");
        setFourthPageDisplay("none");
        setSubmitPageDisplay("none");
        setFormSection(3);
        setConfirmationDisplay("none")

    };

    const handleReceiveValueFromThirdPage = (value: string) => {
        setGuestNumberIconValue(value);

        setDateIconStatus("done");
        setTimeIconStatus("done");
        setGuestNumberIconStatus("done");


        setFirstPageDisplay("none");
        setSecondPageDisplay("none");
        setThirdPageDisplay("none");
        setFourthPageDisplay("");
        setSubmitPageDisplay("none");
        setFormSection(4);
        setConfirmationDisplay("none")

    };

    const handleReceiveValueFromFourthPage = (value: {
        firstName: {
            firstName: string;
        };
        lastName: {
            lastName: string;
        };
        retailerReference: {
            retailerReference: string;
        };
        subsidiary: {
            subsidiary: string;
        }
        email: {
            email: string;
        };
    }) => {
        setFirstPageDisplay("none");
        setSecondPageDisplay("none");
        setThirdPageDisplay("none");
        setFourthPageDisplay("none");
        setSubmitPageDisplay("");
        setConfirmationDisplay("none")


        setUserFirstName(value.firstName.firstName);
        setUserLastName(value.lastName.lastName);
        setUserRetailerReference(value.retailerReference.retailerReference);
        setSubsidiaryBeneficiary(value.subsidiary.subsidiary);
        setUserEmail(value.email.email);

        setFormSection(5);
    };

    const handleSubmitDisplayPage = (value: Booking) => {
        setFirstPageDisplay("none");
        setSecondPageDisplay("none");
        setThirdPageDisplay("none");
        setFourthPageDisplay("none");
        setSubmitPageDisplay("none");
        setConfirmationDisplay("")

    };


    const OnDateIconClicked = () => {
        setDateIconStatus("active");
        setTimeIconStatus("");
        setGuestNumberIconStatus("");

        setFirstPageDisplay("");
        setSecondPageDisplay("none");
        setThirdPageDisplay("none");
        setFourthPageDisplay("none");
        setSubmitPageDisplay("none");

        setFormSection(1);
    }

    const OnTimeIconClicked = () => {
        if (dateIconValue != '') {
            setDateIconStatus("done");
            setTimeIconStatus("active");
            setGuestNumberIconStatus("");

            setFirstPageDisplay("none");
            setSecondPageDisplay("");
            setThirdPageDisplay("none");
            setFourthPageDisplay("none");
            setSubmitPageDisplay("none");
            
            setFormSection(2);
        }
    }

    const OnGuestNumberIconClicked = () => {
        if (timeIconValue != '') {
            setDateIconStatus("done");
            setTimeIconStatus("done");
            setGuestNumberIconStatus("active");

            setFirstPageDisplay("none");
            setSecondPageDisplay("none");
            setThirdPageDisplay("");
            setFourthPageDisplay("none");
            setSubmitPageDisplay("none");

            setFormSection(3);
        }
    }

    const OnBeneficiary = () => {
        setFirstPageDisplay("none");
        setSecondPageDisplay("none");
        setThirdPageDisplay("none");
        setFourthPageDisplay("");
        setSubmitPageDisplay("none");
        setConfirmationDisplay("none")
    }

    const goBack = () => {
        setFormSection(formSection - 1);
        switch(formSection) {
            case 2: 
                OnDateIconClicked();
                return 2;
            case 3:
                OnTimeIconClicked();
                return 3;
            case 4:
                OnGuestNumberIconClicked();
                return 4;
            case 5:
                OnBeneficiary();
                return 5;    
            default: return 0;
        }
    }

    let dateIconClassName = "btn-booking-icon " + dateIconStatus;
    let timeIconClassName = "btn-booking-icon " + timeIconStatus;
    let guestNumberIconClassName = "btn-booking-icon " + guestNumberIconStatus;

    let navMenu = <>
        <button className={dateIconClassName} onClick={OnDateIconClicked}>
            <span><ReactSVG className='font-icon' src={calendar}/></span>
            <span>{dateIconValue}</span>
        </button>
        <button className={timeIconClassName} onClick={OnTimeIconClicked}>
            <ReactSVG className='font-icon' src={clock}/>
            <span>{timeIconValue}</span>
        </button>
        <button className={guestNumberIconClassName} onClick={OnGuestNumberIconClicked}>
            <ReactSVG className='font-icon' src={people}/>
            <span>{guestNumberIconValue}</span>
        </button>
    </>;

      

    async function HandleSubmit() {
        const parsedDate = new Date(dateIconValue);
        const bookingDate = new Date(Date.UTC(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate(), 0, 0))

        let booking: BookingRequestDTO = {
            restaurantId: 1,
            numberOfGuests: parseInt(guestNumberIconValue),
            firstnameBeneficiary: userFirstName,
            lastnameBeneficiary: userLastName,
            retailerReferenceBeneficiary: userRetailerReference,
            subsidiaryBeneficiary: subsidiaryBeneficiary,
            mailBeneficiary: userEmail,
            state: BookingState.PENDING,
            nameEvent: 'Watches and Wonders 2024',
            bookingHour: timeIconValue.toString(),
            bookingDate: bookingDate
        }
        try {
            const createdBooking: Booking = await BookingService.createBooking(booking);
            handleSubmitDisplayPage(createdBooking);
        } catch(error) {
            navigate('/');
        }        
    }

    function onConfirmMessageClose() {
        setConfirmationDisplay("none");
        navigate('/view-booking');
    }

    return (
        <PageTemplate navMenu={navMenu} goBack={goBack}>
            <form className='reservation-booking-form'>
                <div style={{display: firstPageDisplay}}>
                    <FirstPage sendValueToParent={handleReceiveValueFromFirstPage}></FirstPage>
                </div>
                <div style={{display: secondPageDisplay}}>
                    <SecondPage sendValueToParent={handleReceiveValueFromSecondPage}></SecondPage>
                </div>
                <div style={{display: thirdPageDisplay}}>
                    <ThirdPage sendValueToParent={handleReceiveValueFromThirdPage}></ThirdPage>
                </div>
                <div style={{display: fourthPageDisplay}}>
                    <FourthPage sendValueToParent={handleReceiveValueFromFourthPage}></FourthPage>
                </div>
                <div style={{display: submitPageDisplay}}>
                    <SubmitPage affiliate={userSubsidiary} beneficiary={userFirstName !== '' && userLastName !== '' ? userFirstName + " " + userLastName : userName}
                                retailerReference= {userRetailerReference}
                                date={dateIconValue} time={timeIconValue} guest={guestNumberIconValue} name={userName}
                                subsidiary={subsidiaryBeneficiary !== '' ? subsidiaryBeneficiary : userSubsidiary}
                                onClick={HandleSubmit}></SubmitPage>
                </div>
                 <div style={{ display: confirmationDisplay }}>
                    <Modal onClose={onConfirmMessageClose}> 
                        <div className='confirm-creation'>
                            <img className='submit-img-logo' src={rolexLogo}/>
                            <p className="confirm-message">Thank you for submitting your reservation request.</p>
                            <p className="confirm-message-small">Our dedicated team will reach out to you shortly to confirm and complete your booking.</p>
                            <StandardButton disabled={false} status={ButtonStatus.Success} title='Book table now' navigateTo="/view-booking">
                                Back home
                            </StandardButton>
                        </div>
                    </Modal>
                </div>
            </form>

        </PageTemplate>
    );
}

export default BookingPage;