import './booking-filter.scss';
import {ReactSVG} from 'react-svg';
import arrowRight from '../../assets/icons/arrow-right.svg';
import loop from '../../assets/icons/loop.svg';
import close from '../../assets/icons/close.svg';
import chevronLeft from '../../assets/icons/chevron-left.svg';
import circleOne from '../../assets/icons/circle-one.svg';
import {useState} from 'react';

interface BookingFilterProps {
    name: string;
    isPopUpDetail: boolean;
    isShowButton?: boolean;
    filterValue?: Record<string, number>[];
    countFilterResult?: number;
    isPopUpDetailName?: boolean;
    isPopUpDetailRetailerReference?:boolean;
    isPopUpDetailAffiliates?: boolean;
    isPopUpDetailBookingDate?: boolean;
    isPopUpDetailBookingHour?: boolean;
    isPopUpDetailGuestNumber?: boolean;
    isPopUpDetailStatus?: boolean;
    handleFilter?: () => void;
    handlePopUpDetailName?: () => void;
    handlePopUpDetailRetailerReference?: () => void;
    handlePopUpDetailAffiliate?: () => void;
    handlePopUpDetailBookingDate?: () => void;
    handlePopUpDetailNumberOfGuests?: () => void;
    handlePopUpDetailHour?: () => void;
    handlePopUpStatus?: () => void;
    selectedOption?: any;
    handleOptionChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleIsPopUpDetail?: () => void;
    handleDeleteAllFilters?: () => void;
    filterFieldAndValue: Map<string, string[]>;
    handleRemoveField: (key: string, value: string) => void;
}

function BookingFilter({
                           name,
                           isPopUpDetail,
                           isShowButton,
                           filterValue,
                           countFilterResult,
                           handleFilter,
                           handlePopUpDetailName,
                           handlePopUpDetailRetailerReference,
                           handlePopUpDetailAffiliate,
                           handlePopUpDetailBookingDate,
                           handlePopUpDetailNumberOfGuests,
                           handlePopUpDetailHour,
                           handlePopUpStatus,
                           selectedOption,
                           handleOptionChange,
                           handleIsPopUpDetail,
                           handleDeleteAllFilters,
                           isPopUpDetailName,
                           isPopUpDetailRetailerReference,
                           isPopUpDetailAffiliates,
                           isPopUpDetailBookingDate,
                           isPopUpDetailBookingHour,
                           isPopUpDetailGuestNumber,
                           isPopUpDetailStatus,
                           filterFieldAndValue,
                           handleRemoveField
                       }: BookingFilterProps) {

    const [searchTerm, setSearchTerm] = useState('');

    const filteredItems = filterValue?.filter((item) => {
        const key = Object.keys(item)[0];
        return key.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const handleFieldName: string = name === 'Booking date' ? 'BookingDate' : name === 'Number of guests' ? 'NumberOfGuests' : name === "Retailer Reference" ? "RetailerReference" : name;

    return (
        <div className="filter-popup">
            {isPopUpDetail ? (
                <div className='filter-popup-infos'>
                    <div className="filter-header">
                        <div className='filter-header-text'>
                            <button className="filter-header-text-btn" onClick={handleIsPopUpDetail}>
                                <ReactSVG src={chevronLeft} className="filter-header-text-icon"/>
                            </button>
                            <span className="filter-header-text-name"><h2>{name}</h2></span>
                        </div>
                        <button className="filter-header-btn" onClick={handleFilter}>
                            <ReactSVG src={close} className="filter-header-icon"/>
                        </button>
                    </div>
                    <div className="search-container">
                        <ReactSVG src={loop} className="loop-icon"/>
                        <input
                            type="text"
                            placeholder="Search"
                            className="search-bar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className='radio-filter'>
                    <input
                        type="checkbox"
                        name={handleFieldName}
                        value="SelectAll"
                        checked={selectedOption[handleFieldName] && selectedOption[handleFieldName].includes("SelectAll")}
                        className='radio-input'
                        onChange={handleOptionChange}
                    />
                        <span className='radio-filter-text'>Select all</span>
                    </div>
                    <hr/>
                    {filteredItems?.map((item, index) => {
                        const [key, number] = Object.entries(item)[0];
                        return (
                            <div key={index} className='radio-filter'>
                                <input
                                    className='radio-input'
                                    type="checkbox"
                                    id={key}
                                    name={handleFieldName}
                                    value={key}
                                    checked={selectedOption[handleFieldName] && selectedOption[handleFieldName].includes(key)}
                                    onChange={handleOptionChange}
                                />
                                <span className='radio-filter-text'>{key}</span>
                                <span className='radio-filter-number'>{number}</span>
                            </div>
                        );
                    })}
                </div>
            ) : isShowButton ? (
                <div className='filter-popup-infos'>
                    <div className="filter-header">
                        <div className='filter-header-text'>
                            <button className="filter-header-text-btn" onClick={handleFilter}>
                                <ReactSVG src={chevronLeft} className="filter-header-text-icon"/>
                            </button>
                            <span className="filter-header-text-name"><h2>Filter</h2></span>
                        </div>
                        <button className="filter-header-btn" onClick={handleFilter}>
                            <ReactSVG src={close} className="filter-header-icon"/>
                        </button>
                    </div>
                    <div className="div-select">
                        {filterFieldAndValue.size > 0 && (
                            Array.from(filterFieldAndValue.entries()).map(([key, values]) => (
                                <div key={key} className="filter-button-container">
                                    {values.map((value, index) => (
                                        <div key={index}>
                                            <button className="btns-select">
                                                {value}
                                                <span className="delete-icon" onClick={() => handleRemoveField(key, value)}>
                                                    <ReactSVG src={close} />
                                                </span>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            ))                    
                        )}
                    </div>
                    <button className="btn-filter" onClick={handlePopUpDetailName}>
                        <span className='btn-titre'>Name</span>
                        {isPopUpDetailName && <span><ReactSVG src={circleOne} className='btn-selection-icon'/></span>}
                        <ReactSVG src={arrowRight} className='btn-icon'/>
                    </button>
                    <button className="btn-filter" onClick={handlePopUpDetailRetailerReference}>
                        <span className='btn-titre'>Retailer Refence</span>
                        {isPopUpDetailRetailerReference && <span><ReactSVG src={circleOne} className='btn-selection-icon'/></span>}
                        <ReactSVG src={arrowRight} className='btn-icon'/>
                    </button>
                    <button className="btn-filter" onClick={handlePopUpDetailAffiliate}>
                        <span className='btn-titre'>Affiliates</span>
                        {isPopUpDetailAffiliates &&
                            <span><ReactSVG src={circleOne} className='btn-selection-icon'/></span>}
                        <ReactSVG src={arrowRight} className='btn-icon'/>
                    </button>
                    <button className="btn-filter" onClick={handlePopUpDetailBookingDate}>
                        <span className='btn-titre'>Booking date</span>
                        {isPopUpDetailBookingDate &&
                            <span><ReactSVG src={circleOne} className='btn-selection-icon'/></span>}
                        <ReactSVG src={arrowRight} className='btn-icon'/>
                    </button>
                    <button className="btn-filter" onClick={handlePopUpDetailHour}>
                        <span className='btn-titre'>Hour</span>
                        {isPopUpDetailBookingHour &&
                            <span><ReactSVG src={circleOne} className='btn-selection-icon'/></span>}
                        <ReactSVG src={arrowRight} className='btn-icon'/>
                    </button>
                    <button className="btn-filter" onClick={handlePopUpDetailNumberOfGuests}>
                        <span className='btn-titre'>Guest number</span>
                        {isPopUpDetailGuestNumber &&
                            <span><ReactSVG src={circleOne} className='btn-selection-icon'/></span>}
                        <ReactSVG src={arrowRight} className='btn-icon'/>
                    </button>
                    <button className="btn-filter" onClick={handlePopUpStatus}>
                        <span className='btn-titre'>Status</span>
                        {isPopUpDetailStatus && <span><ReactSVG src={circleOne} className='btn-selection-icon'/></span>}
                        <ReactSVG src={arrowRight} className='btn-icon'/>
                    </button>
                    <div className="btns-popup">
                        <button className='filter-popup-delete' onClick={handleDeleteAllFilters}>Delete all filters
                        </button>
                        <button className='filter-popup-results' onClick={handleFilter}>
                            {countFilterResult !== 0 ? `Results (${countFilterResult})` : 'Results'}
                        </button>
                    </div>
                </div>
            ) : (
                <div>

                </div>
            )}
        </div>
    );
}

export default BookingFilter;
