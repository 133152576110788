const env = ((window as any)["_env_"] || {}) as any;

const environement = {
    api: env.APP_API_URL || 'https://dev01.ww-table-app.pro.myrolex.com/api',
    issuerUrl: env.APP_ISSUER_URL || 'https://login.rolex.com/oauth2/ausajnmu67d8xuHgz417',
    clientId: env.APP_CLIENT_ID || '0oaavwmtjri8uCZOP417',
    redirectUri: `${window.location.origin}/login/callback`,
    mailto: env.APP_MAILTO || '',
} 

export default environement;