import PageTemplate from "../../components/page-template/page-template";
import DetailsItem from "../../components/details-item/details-item";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";

import BookingService from "../../services/booking-service";
import { Booking } from "../../models/booking";
import BookingButton, { BookingButtonRole } from "../../components/booking-button/booking-button";
import { useNavigate } from "react-router-dom";
import { BookingRequestDTO } from "../../models/booking-request-dto";

import './booking-detail-page.scss'; 
import { BookingState } from "../../models/BookingState";

function BookingDetailPage(){
    const { id } = useParams();
    const bookingId = id || '';

    const [booking, setBooking] = useState<Booking>();

    const [firstName, setFirstName] = useState<string>();

    const [lastName, setLastName] = useState<string>();

    const [retailerReferenceBeneficiary, setRetailerReferenceBeneficiary] = useState<string>();

    const [mail, setMail] = useState<string>();
    
    const [subsidiary, setSubsidiary] = useState<string>();

    const [bookingDate, setBookingDate] = useState<Date>();

    const [bookingHour, setBookingHour] = useState<string>();

    const [numberOfGuests, setNumberOfGuests] = useState<number>();

    const [isValidEmail, setIsValidEmail] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const result: Booking = await BookingService.getBookingById(bookingId);
            setBooking(result);
          } catch (error) {
            navigate('/');
            console.error('Error fetching Bookings', error);
          }
        };

        fetchData();
      }, []);

      const handleFirstName = (value: string) => {
        setFirstName(value);
      }

      const handleLastName = (value: string) => {
        setLastName(value);
      }

      const handleMail = (value: string) => {
          const isValid = value.endsWith('@rolex.com')
          if (isValid) {
            setIsValidEmail(true);
            setMail(value);
          } else {
              setIsValidEmail(false);
          }
      }
      
      const handleSubsidiaryChange = (value: string) => {
        setSubsidiary(value);
      }

      const handleBookingDate = (value: string) => {
        const parsedDate = new Date(value);
        const bookingDate = new Date(Date.UTC(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate(), 0, 0))
      
        setBookingDate(bookingDate);
      }

      const handleBookingHour = (value: string) => {
        setBookingHour(value);
      }

      const handleNumberOfGuest = (value: number) => {
        setNumberOfGuests(value);
      }

      const handleRetailerReferenceBeneficiary = (value: string) => {
        setRetailerReferenceBeneficiary(value);
      }

    const navigate = useNavigate();
    const goToBookingPage = () => {
        navigate(-1);
    }

    const onClick = async () => {
      if(isValidEmail) {
        let bookingRequestDTO: BookingRequestDTO = {
            id: bookingId,
            firstnameBeneficiary: firstName !== undefined ? firstName : booking?.firstnameBeneficiary,
            lastnameBeneficiary: lastName !== undefined ? lastName : booking?.lastnameBeneficiary,
            retailerReferenceBeneficiary: retailerReferenceBeneficiary !== undefined ? retailerReferenceBeneficiary : booking?.retailerReferenceBeneficiary,
            mailBeneficiary: mail !== undefined ? mail : booking?.mailBeneficiary,
            numberOfGuests: numberOfGuests !== undefined ? numberOfGuests : booking?.numberOfGuests,
            subsidiaryBeneficiary: subsidiary !== undefined ? subsidiary : booking?.subsidiaryBeneficiary,
            bookingHour: bookingHour !== undefined ? bookingHour : booking?.bookingHour?.toString(),
            bookingDate: bookingDate !== undefined ? bookingDate: booking?.bookingDate,
            state: BookingState.PENDING,
        }
        try {
          await BookingService.putBooking(bookingRequestDTO);
          navigate("/view-booking");
        } catch(error) {
          navigate('/');
        }
      }
    }

    return (
        <PageTemplate>
                <h1>Booking Details</h1>
                    <DetailsItem title="First Name" type="text" value={booking?.firstnameBeneficiary || ''} onValueChange={handleFirstName}></DetailsItem>
                    <DetailsItem title="Last name" type="text" value={booking?.lastnameBeneficiary  || ''} onValueChange={handleLastName}></DetailsItem>
                    <DetailsItem title="Retailer Reference" type="text" value={booking?.retailerReferenceBeneficiary  || ''} onValueChange={handleRetailerReferenceBeneficiary}></DetailsItem>
                    <DetailsItem title="Affiliate" type="select" value={booking?.subsidiaryBeneficiary || ''} onValueChange={handleSubsidiaryChange}></DetailsItem>
                    <DetailsItem title="Mail" type="email" value={booking?.mailBeneficiary  || ''} onValueChange={handleMail} isValidEmail={isValidEmail}></DetailsItem>
                    <DetailsItem title="Date" type="select" value={booking?.bookingDate || ''} onValueChange={handleBookingDate}></DetailsItem>
                    <DetailsItem title="Hour" type="select" value={booking?.bookingHour || ''} onValueChange={handleBookingHour}></DetailsItem>
                    <DetailsItem title="Guest" type="number" value={booking?.numberOfGuests || ''} onValueChange={handleNumberOfGuest}></DetailsItem>
                    <DetailsItem title="Statut" type="" value={booking?.state || ''}></DetailsItem>

                <div className="booking-detail">
                    <BookingButton content='Send my request'  role={BookingButtonRole.SendRequest} onClick={onClick} />
                    <BookingButton content='Cancel' role={BookingButtonRole.CancelRequest} onClick={goToBookingPage}/>   
                </div>
        </PageTemplate>
    );
}


export default BookingDetailPage;