import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import StandardButton, {ButtonStatus} from "../../components/buttons/StandardButton";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import DetailsItem from "../../components/details-item/details-item";
import PageTemplate from "../../components/page-template/page-template";
import BookingButton, {BookingButtonRole} from "../../components/booking-button/booking-button";
import './ElementsPage.scss';
import TextField from "../../components/text-field/text-field";
import FormDetailsItem from "../../components/form-details-item/form-details-item";
import BookingDetails from "../../components/booking-details/booking-details";
import {BookingState} from "../../models/BookingState";

const ElementsPage = () => {

    function localOnChange() {
        console.log("elements.tsx => localOnChange");
    }

    return (<PageTemplate>

        {/* <BookingDetails bookingId="" bookingDate="March 6, 2023" bookingGuestNumber={4}
                        bookingStatus={BookingState.VALIDATE} userName={"Mohsen GRAM"} bookingTime={"10:30"}
                        subsidiary=""
                        role=""/>
        <BookingDetails bookingId="" bookingDate="March 6, 2023" bookingGuestNumber={4}
                        bookingStatus={BookingState.REFUSE} userName={"Mohsen GRAM"} bookingTime={"10:30"}
                        subsidiary=""
                        role=""/>
        <BookingDetails bookingId="" bookingDate="March 6, 2023" bookingGuestNumber={4}
                        bookingStatus={BookingState.PENDING} userName={"Mohsen GRAM"} bookingTime={"10:30"}
                        subsidiary=""
                        role=""
                        /> */}

        <StandardButton title="this is a value from elements page" disabled={false} status={ButtonStatus.Success}
                        navigateTo="/">
            <FontAwesomeIcon icon={faHome}/>
            Home
        </StandardButton>
        <div>
            <TextField placeHolder="haha" value='' onChange={localOnChange}></TextField>
        </div>

        <div>
            <DetailsItem title="Id" type="text" value="123"></DetailsItem>
            <DetailsItem title="Name" type="text" value="HAMZA"></DetailsItem>
        </div>

        <BookingButton content="Hello, wolrd" disabled={false}></BookingButton>

        <div className="book-time">
            <BookingButton content="11:00" role={BookingButtonRole.BookTime}/>
            <BookingButton content="11:30" role={BookingButtonRole.BookTime}/>
            <br/>
            <BookingButton content="12:00" role={BookingButtonRole.BookTime}/>
            <BookingButton content="12:30" role={BookingButtonRole.BookTime}/>
            <br/>
            <BookingButton content="13:00" role={BookingButtonRole.BookTime}/>
            <BookingButton content="13:30" role={BookingButtonRole.BookTime}/>
            <br/>
            <BookingButton content="14:00" role={BookingButtonRole.BookTime}/>
            <BookingButton content="14:30" role={BookingButtonRole.BookTime}/>
        </div>

        <div>
            <BookingButton content="1" role={BookingButtonRole.Numbers}/>
            <BookingButton content="2" role={BookingButtonRole.Numbers}/>
            <BookingButton content="3" role={BookingButtonRole.Numbers}/>
        </div>
        <div>
            <BookingButton content="4" role={BookingButtonRole.Numbers}/>
            <BookingButton content="5" role={BookingButtonRole.Numbers}/>
            <BookingButton content="6" role={BookingButtonRole.Numbers}/>
        </div>
        <div>
            <BookingButton content="7" role={BookingButtonRole.Numbers}/>
            <BookingButton content="8" role={BookingButtonRole.Numbers}/>
            <BookingButton content="9" role={BookingButtonRole.Numbers}/>
        </div>
        <div>
            <BookingButton content="11" role={BookingButtonRole.Numbers}/>
            <BookingButton content="12" role={BookingButtonRole.Numbers}/>
            <BookingButton content="12+" role={BookingButtonRole.Numbers}/>
        </div>

        <div>
            <BookingButton content="Cancel" role={BookingButtonRole.Cancel}/>
        </div>


        <div>
            <FormDetailsItem label="Name" itemValue="Hamza"></FormDetailsItem>
        </div>
    </PageTemplate>);
}

export default ElementsPage;