import OktaAuth, { UserClaims } from '@okta/okta-auth-js';
import oktaAuth from '../security/okta-config';

const loggedInUserName="Logged In User Name"

const GetUserName = () => {
    let userName = localStorage.getItem(loggedInUserName);
    return userName ? userName : "";
};


export const SetUserName = (oktaAuth:OktaAuth) => {
    oktaAuth.getUser().then(user=>localStorage.setItem(loggedInUserName,user.name?user.name:""));
}


export default GetUserName;