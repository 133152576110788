import { useEffect, useState } from 'react';
import { Subsidiary } from '../../models/subsidiary';
import './text-field.scss';
import SubsidiaryService from '../../services/subsidiary-service';
import { useNavigate } from 'react-router-dom';


export enum TextFieldTypes {
    Email,
    Text,
    Password
}

interface TextFieldProps {
    type?: TextFieldTypes;
    placeHolder: string;
    value: string;
    onChange: (value: string) => void;
    isValidEmail?: boolean; 
    handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void; 
    isEmptyField?: boolean;   
}

function TextField({ type, placeHolder, onChange, value, isValidEmail, isEmptyField, handleKeyDown }: TextFieldProps) {

    const [subsidiaries, setSubsidiaries] = useState<Subsidiary[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        getSubsidiaries();
    }, []);

    let inputType = 'text';
    let minSize=5;
    let pattern="";
    switch (type) {
        case TextFieldTypes.Email:
            inputType = 'email'; break;
        default:
            inputType = 'text'; minSize=10; pattern=".+@example\.com";break;

    }

    const getSubsidiaries = async () => {
        try {
            const result: Subsidiary[] = await SubsidiaryService.getAllSubsidiaries();
            if(result !== null) {
                setSubsidiaries(result);
            } else {
                navigate('/');
            }
        } catch(error) {
            navigate('/');
        }
    }

    return (<>
        {placeHolder === 'Affiliate' ? (
        <select
            className='select-text-field'
            required
            onChange={(e) => onChange(e.target.value)}
        >
            <option value="">{placeHolder}</option>
            {subsidiaries.map((subsidiary) => (
                <option key={subsidiary.code} value={subsidiary.name}>
                    {subsidiary.name}
                </option>
            ))}
        </select>
        ) : (
        <>
            {!isValidEmail && type === TextFieldTypes.Email && (
                <div className="error-message">Please use the recipient's Rolex email</div>
            )}
            {isEmptyField && placeHolder === "First Name" && (
                <div className="error-message">Please fill in all beneficiary-related information or remove them</div>
            )}
            <input
                className={`txt-standard ${!isValidEmail && type === TextFieldTypes.Email ? 'error' : ''}`}
                size={minSize}
                pattern={pattern}
                required
                type={inputType}
                placeholder={placeHolder}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onKeyDown={handleKeyDown}
            />
        </>
        )}
    </>);
}

export default TextField;