import http from "../utils/http-common";
import { ApiResponse } from "../models/api-response";
import { User } from "../models/user";

const putUser = async (idToken: string) => {
    try {
        const response = await http.put<ApiResponse<User>>(`/users?idtoken=${idToken}`);
        return response.data.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const getCurrentUser = async () => {
    try {
        const response = await http.get<ApiResponse<User>>(`/users`);
        return response.data.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const UserService = {
    putUser,
    getCurrentUser
};

export default UserService;
