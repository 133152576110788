import './booking-button.scss';

export enum BookingButtonRole {
    Cancel,
    BookTime,
    Numbers,
    SendRequest,
    CancelRequest
}

interface BookingButtonProps {
    disabled?: boolean;
    content: string;
    role?: BookingButtonRole;
    onClick?: () => void;
    sendValueToParent?: (value: string) => void;
    selectedValue?: string;
}


function BookingButton({disabled, content, role, onClick, sendValueToParent, selectedValue}: BookingButtonProps) {

    let buttonClassName = 'standard-btn';
    switch (role) {
        case BookingButtonRole.Cancel:
            buttonClassName += " cancel";
            break;
        case BookingButtonRole.BookTime:
            buttonClassName += " book-time";
            break;
        case BookingButtonRole.Numbers:
            buttonClassName += " number";
            break;
        case BookingButtonRole.SendRequest:
            buttonClassName += " request-button";
            break;
        case BookingButtonRole.CancelRequest:
            buttonClassName += " request-button cancel";
            break;
        default:
            break;
    }
    const handleSendValue = () => {
        const valueToSend = content; // Mettez la valeur que vous souhaitez envoyer
        if (sendValueToParent) {
            sendValueToParent(valueToSend);
        }
    };
    return (
        <button className={`${buttonClassName} ${selectedValue === content ? 'green' : ''}`} disabled={disabled}
                onClick={() => {
                    handleSendValue();
                    if (onClick) {
                        onClick();
                    }
                }} type="button">
            {content}
        </button>
    );
}

export default BookingButton;