import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './details-item.scss';
import '../booking-details/booking-details.scss';

import {faPenToSquare} from '@fortawesome/free-solid-svg-icons';
import {useEffect, useState} from 'react';
import times from '../../pages/booking-page/second-page/times.json';
import dates from '../../pages/booking-page/first-page/dates.json';

import check from '../../assets/icons/check.svg';
import {ReactSVG} from 'react-svg';
import { Subsidiary } from '../../models/subsidiary';
import SubsidiaryService from '../../services/subsidiary-service';
import { useNavigate } from "react-router-dom";

interface DetailsItemProps {
    title: string;
    value: any;
    type: string;
    onValueChange?: (newValue: any) => void;
    isValidEmail?: boolean; 
}

function DetailsItem({title, value, type, onValueChange, isValidEmail}: DetailsItemProps) {
    const [isTextFieldEnabled, setIsTextFieldEnabled] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    const [subsidiaries, setSubsidiaries] = useState<Subsidiary[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        getSubsidiaries();
    }, []);

    const getSubsidiaries = async () => {
        try {
            const result: Subsidiary[] = await SubsidiaryService.getAllSubsidiaries();
            setSubsidiaries(result);
        } catch(error) {
            navigate('/')
        }        
    }

    const EditAction = () => {
        setIsTextFieldEnabled(!isTextFieldEnabled);
    };

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (isTextFieldEnabled) {
            let newValue: string | number | Date | undefined;

            if (e.target instanceof HTMLInputElement) {
                if (e.target.type === 'number') {
                    newValue = parseFloat(e.target.value);
                } else if (e.target.type === 'date') {
                    newValue = new Date(e.target.value);
                } else {
                    newValue = e.target.value;
                }
            } else if (e.target instanceof HTMLSelectElement) {
                newValue = e.target.value;
            } else {
                newValue = undefined;
            }

            setInputValue(newValue);

            if (onValueChange) {
                onValueChange(newValue);
            }
        }
    };

    const formatDate = (inputDate: Date | undefined) => {
        if (inputDate) {
            return new Date(inputDate).toLocaleDateString('en-US', {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            });
        } else {
            return '';
        }
    };

    let bookingDetailsStatusClassName = "booking-details-status";
    let bookingDetailsStatusContent = "";
    switch (inputValue) {
        case 'VALIDATE':
            bookingDetailsStatusClassName += " confirmed";
            bookingDetailsStatusContent = "Confirmed";
            break;
        case 'PENDING':
            bookingDetailsStatusClassName += " pending";
            bookingDetailsStatusContent = "Pending";
            break;
        case 'REFUSE':
            bookingDetailsStatusClassName += " refused";
            bookingDetailsStatusContent = "Refused";
            break;
        default:
            bookingDetailsStatusClassName += "";
            break;
    }

    return (
        <div className='item-global'>
            {type === 'select' && title === 'Date' && (
                <>
                    <hr className='item-hr'/>
                </>
            )}
            {!isValidEmail && type === 'email' && (
                        <div className="error-message">Please use the recipient's Rolex email</div>
            )}
            <div className='item'>
                <div className='item-discription'>{title}</div>
                <div className='item-value'>
                    {type !== 'select' && title !== 'Statut' ? (
                        <input
                            className={`item-value-input ${!isValidEmail && type === 'email' ? 'error' : ''}`}
                            type={type}
                            value={inputValue}
                            onChange={handleInputChange}
                            disabled={!isTextFieldEnabled}
                        />
                    ) : type === 'select' && title === 'Affiliate' ? (
                        <select
                            className='item-value-select-subsidiary'
                            value={inputValue}
                            onChange={handleInputChange}
                            disabled={!isTextFieldEnabled}
                        >
                        {subsidiaries.map((subsidiary) => (
                            <option key={subsidiary.code} value={subsidiary.name}>
                                {subsidiary.name}
                            </option>
                        ))}
                        </select>
                    ) : type === 'select' && title === 'Hour' ? (
                        <select
                            className='item-value-select item-value-select-hour'
                            value={inputValue}
                            onChange={handleInputChange}
                            disabled={!isTextFieldEnabled}
                        >
                            {times.times.map((time) => (
                                <option key={time} value={time}>
                                    {time}
                                </option>
                            ))}
                        </select>
                    ) : type === 'select' && title === 'Date' ? (
                        <select
                            className='item-value-select-date'
                            value={formatDate(inputValue)}
                            onChange={handleInputChange}
                            disabled={!isTextFieldEnabled}
                        >
                            {dates.dates.map((date) => (
                                <option key={date} value={date}>
                                    {date}
                                </option>
                            ))}
                        </select>
                    ) : null}
                </div>
                {type !== '' ? (

                    <div className='item-icon'>
                        <button className={`edit-item-btn ${!isTextFieldEnabled ? '' : 'active'}`} onClick={EditAction}>
                            {!isTextFieldEnabled ? (
                                <FontAwesomeIcon icon={faPenToSquare}/>
                            ) : (
                                <ReactSVG src={check} className='font'/>
                            )}
                        </button>
                    </div>
                ) : (
                    <div className='item-value'>
                        <button className={bookingDetailsStatusClassName}>{bookingDetailsStatusContent}</button>
                    </div>
                )}
            </div>
            {title === 'Guest' && (
                <>
                    <hr className='item-hr'/>
                </>
            )}
        </div>
    );
}


export default DetailsItem;
