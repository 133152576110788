import PageTemplate from "../../components/page-template/page-template";

function ContactPage(){
    return (
        <PageTemplate>
            <h1 style={{color:"black"}}>contact us</h1>
        </PageTemplate>
      );
}

export default ContactPage;