import http from "../utils/http-common";
import { ApiResponse } from "../models/api-response";
import { Subsidiary } from "../models/subsidiary";

const getAllSubsidiaries = async () => {
    try {
        const response = await http.get<ApiResponse<Subsidiary[]>>(`/subsidiaries`);
        return response.data.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const findSubsidiaryByCode = async (subsidiaryCode: string) => {
    try {
        const response = await http.get<ApiResponse<Subsidiary>>(`/subsidiaries/${subsidiaryCode}`);
        return response.data.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const SubsidiaryService = {
    getAllSubsidiaries,
    findSubsidiaryByCode
}

export default SubsidiaryService;