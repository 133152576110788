import { ReactNode } from 'react';
import './standardButton.scss';
import { useNavigate } from "react-router-dom";

export enum ButtonStatus {
    Success,
    Info,
    Fail,
    Alert,
    BtnBooking,
    Contact
}

interface StandardButtonProps {
    title: string;
    disabled: boolean;
    status: ButtonStatus;
    navigateTo: string;
    children?: ReactNode;
}

const StandardButton: React.FC<StandardButtonProps> = ({ title, disabled, status, navigateTo, children }) => {
    const navigate = useNavigate();
    function handleClick() {
        navigate(navigateTo);
    }
    let buttonClassName = null;
    switch (status) {
        case ButtonStatus.Alert:
            buttonClassName = "alert"; break;
        case ButtonStatus.Fail:
            buttonClassName = "failure"; break;
        case ButtonStatus.Info:
            buttonClassName = "info"; break;
        case ButtonStatus.BtnBooking:
            buttonClassName = "btn-booking"; break;
        case ButtonStatus.Contact:
            buttonClassName = "btn-contact"; break;
        default:
            buttonClassName = "success"; break;
    }

    return <button className={buttonClassName} role="button" disabled={disabled} value={title} onClick={handleClick}>
        <div className='btn-content'>{children}</div>
    </button>;
}

export default StandardButton;

