import Cover from "../../components/cover/cover";
import './restaurant-page.scss';
import './restaurant-page-mobile.scss';
import { useEffect } from 'react';
import UserService from '../../services/user-service';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';

const RestaurantPage = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                await UserService.putUser(idToken);        
            } catch (error) {
                navigate('/');
                console.error('Error fetching Bookings', error);
            }
        };
        fetchData()   
    }, []);

    const { authState } = useOktaAuth();
    if (!authState || !authState.isAuthenticated) {
        return null;
    }

    const accessToken = authState.accessToken?.accessToken ?? null;
    if (accessToken) {
        localStorage.setItem('token', accessToken);
    }

    const idToken: string = authState.idToken?.idToken?.toString() || "";

    return (
        <section>
            <div className="restaurant-page cover-container">
                <Cover />
            </div>
        </section>
    );
};

export default RestaurantPage;