import { ReactSVG } from 'react-svg';
import './icon.scss';

interface IconProps {
    icon: string;
}

const Icon: React.FC<IconProps> = ({ icon }) => {
    return <div className="component icon-container">
        <ReactSVG src={icon} />
    </div>;
}

export default Icon;