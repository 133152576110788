import { OktaAuth } from "@okta/okta-auth-js";
import environement from "../environments/environment";


const oktaAuthConfig = new OktaAuth({
    issuer: environement.issuerUrl,
    clientId: environement.clientId,
    redirectUri: environement.redirectUri,
    scopes: ["openid", "profile", "email", "offline_access"],
    pkce: true,
    headers: {
        "Content-Type": "application/json"
    },
    state: "111"

});

export default oktaAuthConfig;