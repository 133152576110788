import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StandardButton, { ButtonStatus } from '../buttons/StandardButton';
import './cover.scss'
import logoRolex from '../../assets/images/logo_couronne.webp';
import calendar from '../../assets/icons/calendar.svg';
import { ReactSVG } from 'react-svg';
import {useOktaAuth} from '@okta/okta-react';
import environement from '../../environments/environment';
import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { UserRole } from '../../models/UserRole';
import UserService from '../../services/user-service';

function Cover() {

    const navigate = useNavigate();
    const [userRole, setUserRole] = useState<UserRole>(UserRole.USER);
    
    useEffect(() => {
        getCurrentUser();
    }, []);

    const getCurrentUser = async() => {
        try {
            let result = await UserService.getCurrentUser();
            if(result !== null) {
                setUserRole(result.role);
            } else {
                navigate('/');
            }
        } catch(error) {
            navigate('/');
        }
    } 

    const {authState} = useOktaAuth();
    if (!authState || !authState.isAuthenticated) {
        return null;
    }
    const mailto = environement.mailto;

    return (
        <div className='component cover-container' style={{ display: 'flex' }}>
            <div className="darker"></div>
            <div className='cover'>
                <div className="logo">
                    <img src={logoRolex} />
                </div>
                <p className='title-home'>Watches and Wonders 2024</p>
                <div>
                    <h1>
                        Table Booking
                        <br />
                        Service
                    </h1>
                </div>
                <div className='cover-btn'>
                    <StandardButton disabled={false} status={ButtonStatus.Success} title='Book table now' navigateTo="/booking">
                        <ReactSVG src={calendar}/>
                        Book table now
                    </StandardButton>
                    <br />
                        <StandardButton disabled={false} status={ButtonStatus.BtnBooking} title='Manage booking' navigateTo="/view-booking">
                            <span className='btn-content'>{ userRole !== UserRole.ADMIN ? 'My bookings' : 'Manage bookings'} </span>
                        </StandardButton>
                    <br />
                    <a href={`mailto:${mailto}`}>
                    <StandardButton disabled={false} status={ButtonStatus.Contact} title='Contact us' navigateTo="/">
                        <span> <FontAwesomeIcon icon={faEnvelope} />
                        <span className='btn-contact-text'>Contact us
                        </span>
                        </span>
                    </StandardButton>
                    </a>
                </div>
            </div>
        </div>
    );
}
export default Cover;
