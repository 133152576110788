import BookingButton, { BookingButtonRole } from "../../../components/booking-button/booking-button";
import times from './times.json';
import { useState } from 'react';

interface SecondPageProps {
    sendValueToParent?: (value: string) => void;
}



function SecondPage({ sendValueToParent }: SecondPageProps) {
    const [selectedValue, setSelectedValue] = useState<string>('');

    let recievedValueFromButton = "";

    const handleReceiveValueFromChild = (value: string) => {
        recievedValueFromButton = value;
        setSelectedValue(value);
    };

    const handleSendValue = () => {
        const valueToSend = recievedValueFromButton;
        if (sendValueToParent) {
            sendValueToParent(valueToSend);
        }
    };


    return (
        <>
            <div className="page-title">
                <p>Book a table</p>
                <h1 className="title-second">Please select an hour</h1>
            </div>
            <div className="page-content">
                {times.times.map((time, index) => (
                    index % 2 === 0 ? (
                        <div key={index / 2} className="time-group">
                            <BookingButton key={index} content={time} onClick={handleSendValue} sendValueToParent={handleReceiveValueFromChild} role={BookingButtonRole.BookTime} 
                            selectedValue={selectedValue} />
                            {index + 1 < times.times.length && (
                                <BookingButton key={index + 1} content={times.times[index + 1]} onClick={handleSendValue} sendValueToParent={handleReceiveValueFromChild} role={BookingButtonRole.BookTime} 
                                selectedValue={selectedValue} />
                            )}
                        </div>
                    ) : null
                ))}
            </div>
        </>
    );
}

export default SecondPage;