import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './booking-details.scss';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';
import {BookingState} from '../../models/BookingState';
import {BookingRequestDTO} from '../../models/booking-request-dto';
import BookingService from '../../services/booking-service';
import {ReactSVG} from 'react-svg';
import update from '../../assets/icons/update.svg';
import {useEffect, useState} from 'react';
import { UserRole } from '../../models/UserRole';
import { useNavigate } from 'react-router-dom';

interface BookingDetailsProps {
    bookingId: string;
    userName: string;
    bookingDate: string;
    bookingTime: string;
    bookingGuestNumber: number;
    bookingStatus: BookingState;
    subsidiary: string;
    userRole: UserRole;
    handleRemoveBooking: (bookingId: string) => void;
}

function BookingDetails({
                            bookingId,
                            userName,
                            bookingDate,
                            bookingTime,
                            bookingGuestNumber,
                            bookingStatus,
                            subsidiary,
                            userRole,
                            handleRemoveBooking
                        }: BookingDetailsProps) {

    const [showRefusePopup, setShowRefusePopup] = useState(false);
    const [refuseReason, setRefuseReason] = useState('');
    const [currentBookingStatus, setCurrentBookingStatus] = useState<BookingState>(bookingStatus);
    const navigate = useNavigate();
    
    useEffect(() => {
    }, [currentBookingStatus]);

    const handleRefuseClick = () => {
        setShowRefusePopup(true);
    };

    const handleConfirmRefuse = async () => {
        try {
            let bookingRequestDTO: BookingRequestDTO = {
                id: bookingId,
                state: BookingState.REFUSE,
                reasonForRefusal: refuseReason,
            }
            await BookingService.putBooking(bookingRequestDTO);
            if(userRole === UserRole.ADMIN) {
                setCurrentBookingStatus(BookingState.REFUSE);
            } else {
                handleRemoveBooking(bookingId);
            }
        } catch (error) {
            navigate('/');
            console.error('Error during the cancellation of the reservation :', error);
        }

        setShowRefusePopup(false);
    };

    const handleCancelRefuse = () => {
        setShowRefusePopup(false);
    };

    let bookingDetailsStatusClassName = "booking-details-status";
    let bookingDetailsStatusContent = "";
    switch (currentBookingStatus) {
        case BookingState.VALIDATE:
            bookingDetailsStatusClassName += " confirmed";
            bookingDetailsStatusContent = "Confirmed";
            break;
        case BookingState.PENDING:
            bookingDetailsStatusClassName += " pending";
            bookingDetailsStatusContent = "Pending";
            break;
        case BookingState.REFUSE:
            bookingDetailsStatusClassName += " refused";
            bookingDetailsStatusContent = "Refused";
            break;
        default:
            bookingDetailsStatusClassName += "";
            break;
    }

    const validateBooking = async () => {
        try {
            let bookingRequestDTO: BookingRequestDTO = {
                id: bookingId,
                state: BookingState.VALIDATE
            }

            await BookingService.putBooking(bookingRequestDTO);
            setCurrentBookingStatus(BookingState.VALIDATE);
        } catch (error) {
            navigate('/');
            console.error('Error during the validation of the booking :', error);
        }
    }

    return (<>
        <div className='booking-details-container'>
            <div className='first-div'>
                <div className='booking-details-name'>
                    <span className='booking-details-name-1'>Name</span>
                    <span className='booking-details-name-2'>{userName}</span>
                </div>
                <div className='booking-details-time'>
                    <span className='booking-details-time-1'>Hour</span>
                    <span className='booking-details-time-2'>{bookingTime}</span>
                </div>
            </div>
            <div className='second-div'>
                <div className='booking-details-date'>
                    <span className='booking-details-date-1'>Date</span>
                    <span className='booking-details-date-2'>{bookingDate}</span>
                </div>
                <div className='booking-details-guests'>
                    <span className='booking-details-guests-1'>Guests</span>
                    <span className='booking-details-guests-2'>{bookingGuestNumber}</span>
                </div>
            </div>
            <div className='third-div'>
                <div className='booking-details-confirmation-container'>
                    <button className={bookingDetailsStatusClassName}>
                        <FontAwesomeIcon icon={faCheck} className='font'/>
                        {bookingDetailsStatusContent}
                    </button>
                </div>
                <div className='booking-details-subsidiary'>
                    <span className='booking-details-subsidiary-1'>Affiliate</span>
                    <span className='booking-details-subsidiary-2'>{subsidiary}</span>
                </div>
            </div>
            <div className='booking-confirmation'>
                <>  { userRole === UserRole.ADMIN && (
                    <button className={`booking-validated ${currentBookingStatus === BookingState.PENDING ? '' : 'disabled'}`}
                            onClick={validateBooking} disabled={currentBookingStatus !== BookingState.PENDING}>
                        <FontAwesomeIcon icon={faCheck}/>
                    </button>
                    )}
                    { (userRole === UserRole.ADMIN || currentBookingStatus === BookingState.PENDING) && (
                        <button className={`booking-refuse ${currentBookingStatus === BookingState.PENDING ? '' : 'disabled'}`}
                                onClick={handleRefuseClick}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </button>
                    )}
                    { (userRole === UserRole.ADMIN || currentBookingStatus === BookingState.PENDING)  && (
                        <button className='button booking-update'>
                            <a href={`/booking-detail/${bookingId}`}>
                                <ReactSVG src={update} className='font'/>
                            </a>
                        </button>
                    )}
                    {showRefusePopup && (
                        <div className="refuse-popup">
                            {(userRole === UserRole.ADMIN) ? (
                                <div>
                                    <p>Enter the reason for refusal</p>
                                    <textarea
                                        value={refuseReason}
                                        onChange={(e) => setRefuseReason(e.target.value)}
                                    />
                                </div>
                            ) : (
                                <p>Are you sure you want to cancel the reservation ?</p>
                            )}
                            <button className='refuse-popup-confirmation' onClick={handleConfirmRefuse}>Confirm</button>
                            <button className='refuse-popup-annulation' onClick={handleCancelRefuse}>Cancel</button>
                        </div>
                    )}
                </>
            </div>
        </div>
    </>);
}

export default BookingDetails;