import { useOktaAuth } from "@okta/okta-react";
import { Outlet } from "react-router-dom";

function PrivateRoute() {
    const { oktaAuth, authState } = useOktaAuth();

    if(!authState) {
        return <div>Loading...</div>;
    } else if(!authState.isAuthenticated) {
        oktaAuth.setOriginalUri(window.location.pathname + window.location.search);
        oktaAuth.signInWithRedirect();
        return <div>Loading...</div>;
    } else {
        return (<Outlet /> );
    }

}

export default PrivateRoute;